body {
    background-color: #fafafb;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.list {
    background: #eceff5 !important;
    margin-bottom: 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    color: #455560;
}

.link {
    color: #455560 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
}

* {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.MuiTypography-h5 {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 1.5 !important;
    color: #1a3353 !important;
    font-size: 30px !important;
    font-weight: 500 !important;
}

.MuiTypography-subtitle1 {
    color: #72849a !important;
    font-size: 15px !important;
    line-height: 1.75;
}

.MuiTypography-h6 {
    color: #1a3353 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.css-i4bv87-MuiSvgIcon-root {
    color: #3f51b5 !important;
}

.MuiTypography-body2 {
    font-size: 0.8rem !important;
}

.passwordField {
    width: 270px !important;
}

/* ================================= */
.register-logo {
    width: 150px;
    height: 40px;
    margin-top: 10%;
}

.register-ad-bg-image {
    /* height: 600px; */
    width: 100%;
    overflow: "hidden";
    height: 170%;
    /* max-height: 160%; */

}

.register-ad-text {
    position: absolute;
    top: 0;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 29px;
}

.register-ad-text h3 {
    color: #fff;
}

.register-ad-text p {
    text-align: center;
    margin-top: 4%;
    color: #fff;
    /* margin-left: 20px;
      margin-right: 20px; */
    padding-left: 41px;
    padding-right: 41px;
}

.register-ad-image {
    height: 250px;
    margin-top: 7%;
}

.register-footer {
    position: absolute;
    right: 0%;
    bottom: 2%;
    top: 110%;
    color: #fff;
    align-items: "right";
}

/* auth */
.rightBg {
    background-image: url(../assets/images/auth/img_BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.register-logo {
    width: 150px;
    height: 40px;
    margin-top: 10%;
}

.register-ad-bg-image {
    /* height: 600px; */
    width: 100%;
    overflow: "hidden";
    height: 170%;
    /* max-height: 160%; */

}

.register-ad-text {
    position: absolute;
    top: 0;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 29px;
}

.register-ad-text h3 {
    color: #fff;
}

.register-ad-text p {
    text-align: center;
    margin-top: 4%;
    color: #fff;
    /* margin-left: 20px;
      margin-right: 20px; */
    padding-left: 41px;
    padding-right: 41px;
}

.register-ad-image {
    height: 250px;
    margin-top: 7%;
}

.register-footer {
    position: absolute;
    right: 0%;
    bottom: 2%;
    top: 110%;
    color: #fff;
    align-items: "right";
}

/* === */
.container {
    background-color: #1A3353;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.paginationContainer {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
}

.paginationSubContainer {
    text-align: center;
    align-items: center;
    font-size: 13px;
    background-color: white;
    color: black;
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
}

.previousArrow {
    background-color: white;
    transform: none;
    cursor: pointer;
    border: none;
    margin-left: 5px;
}

.tag {
    margin-left: 15px;
    margin-top: 15px;
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.deleteIcon {
    display: flex;
    justify-content: flex-end;
}

.creatorContainer {
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
}

.deleteContainer {
    width: 28%;
}

.avtarGroup {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.breadcomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.saveClose {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 20px;
}

.leadContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.typography {
    border-bottom: 1px solid lightgray;
    width: 100%;
    color: #1A3353;
}

.fieldContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.fieldSubContainer {
    width: 40%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.fieldTitle {
    margin-top: 5px;
    margin-right: 15px;
    font-size: 15px;
    width: 29%;
    text-align: right;
    font-weight: 500;
    color: "#1A3353";
}

.assignTo {
    margin-left: 13px;
    font-size: 13px;
    width: 22%;
    text-align: right;
    font-weight: bold;
}

.fieldContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}

.DescriptionDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}

.descriptionSubContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.descriptionTitle {
    margin-right: 10px;
    font-size: 13px;
    width: 10%;
    text-align: right;
    font-weight: bold;
}

.open {
    color: blue;
    background-color: #EBF7F5;
    text-transform: lowercase;
    border-radius: 8px 8px 0px 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* new */
.custom-textfield .root {
    border-left: 2px solid red;
    height: 40px;
}

.custom-textfield .fieldHeight {
    height: 40px;
}

/* Define styles for the custom-select class (closed state) */
.custom-select {
    background-color: white;
    border: none;
    border-radius: 7px !important;
    width: 198px;
    height: 40px;
    max-height: 40px;
    margin-right: 10px;
    text-align: left;
}

.custom-select-icon {
    height: 40px;
    width: 22px;
    background-color: whitesmoke;
    padding: 0px 4px 0px 4px;
    border-radius: 0px 7px 7px 0px;
    cursor: pointer;
}

/*  */
.select {
    height: 40px;
}

.select-icon-background {
    background-color: whitesmoke;
    height: 40px;
    width: 50px;
    cursor: pointer;
}

.select-icon {
    font-size: 20px;
    margin: 10px 0px 0px 9px;
}

/* header-button */
.header-button {
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    height: 40px;
    color: white;
    border-radius: 7px;
}

.add-button {
    text-transform: capitalize !important;
    font-weight: bold !important;
    font-size: 15px !important;
    height: 40px;
    color: white;
    margin-right: -13px;
    border-radius: 7px;
}

.plus-icon {
    color: #1976d2;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 10px;
}

/*  input*/
.input-plus-icon {
    height: 12px;
    width: 12px;
}

/* accordion */
.divider {
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-color: #0000001f !important;
    border-bottom-width: 2px !important;
    margin-bottom: 10px !important;
    margin-top: -5px !important;
}

.accordion-header {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1A3353;
}

/* helpertext */
.helperText {
    color: #d32f2f !important;
}

/* date */
/* details */

.title2 {
    font-size: 16px;
    font-weight: 600;
    color: #1a3353f0;
}

.title3 {
    font-size: 16px;
    color: gray;
    margin-top: 5%;
}

.detailList {
    padding: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* table */
.tableCell-link {
    text-align: left;
    cursor: pointer;
    color: #3E79F7 !important;
    text-transform: none;
    border: 0 !important;
}

.tableCell {
    text-align: left;
    color: rgb(26, 51, 83);
    border: 0 !important;
}

/* leads */
.lead-box {
    padding: 10px;
}

.lead-box1 {
    border-radius: 5px;
    border: 1px solid lightgray;
    background-color: white;
    padding: 5px;
    min-height: 70px;
}

.lead-row1 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 10px;
}

.lead-row2 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px 10px 0px 10px;
}

.lead-row2-col1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.lead-row2-col2 {
    color: gray;
    font-size: 16px;
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

/* comment */
.CustomInputBoxWrapper {
    width: '200px';
    height: '400px';
    min-height: '400px';
    border-radius: '4px';
    border: '1px solid #ccc';
    overflow-y: 'auto';
    padding: '8px';
    outline: 'none';
    position: 'relative';
}
.AttachedFilesList{
    list-style: 'none';
    padding: 0;
    margin-top: '8px';
}
.AttachedFileItem{
    margin-top: '4px';
}
.ActionButtonsWrapper{
    border-top: '1px solid #ccc';
    padding-top: '8px';
    display: 'flex';
    justify-content: 'space-between';
}
